.demo {
  /*
   Template Name: MedService - Medical & Medical Health Landing Page Template
   Theme URL: https://themeforest.net/user/jthemes
   Description: MedService - Medical & Medical Health Landing Page Template
   Author: Jthemes
   Author URL: https://themeforest.net/user/jthemes
   Version: 1.0.0
   Tags: Responsive, HTML5 Template, Jthemes, One Page, Landing, Medical, Health, Healthcare, Doctor, Clinic, Care, Hospital
   */
}
.demo html {
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
}
.demo body {
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #333;
  font-weight: 300;
  background-color: #f8f9fb;
  /*------------------------------------------------------------------*/
  /*   IE10 in Windows 8 and Windows Phone 8 Bug fix
   /*-----------------------------------------------------------------*/
}
@-webkit-viewport {
  .demo {
    width: device-width;
  }
}
@-moz-viewport {
  width: device-width;
}

@-ms-viewport {
  width: device-width;
}
@-o-viewport {
  width: device-width;
}
@viewport {
  /*------------------------------------------*/
  /*	PAGE CONTENT
   /*------------------------------------------*/

  width: device-width;
}

.demo #page {
  overflow: hidden;
  /*------------------------------------------*/
  /*   BACKGROUND SETTINGS FOR SECTION
   /*------------------------------------------*/
}
.demo .bg-scroll {
  width: 100%;
  background-attachment: fixed !important;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.demo .bg-fixed {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  /*------------------------------------------*/
  /*  HEADERS
   /*------------------------------------------*/
}
.demo h1,
.demo h2,
.demo h3,
.demo h4,
.demo h5,
.demo h6 {
  color: #222;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  letter-spacing: -0.25px;
  /*------------------------------------------*/
  /*  CONTENT
   /*------------------------------------------*/
}
.demo ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.demo a {
  text-decoration: none;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.demo a:hover {
  /* color: #444; */
  text-decoration: none;
}
.demo a:focus {
  outline: none;
  text-decoration: none;
}
.demo .img-responsive {
  display: inline-block;
}
.demo .btn {
  background-color: #3ca4ed;
  color: #fff !important;
  font-size: 0.85rem;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  padding: 15px 30px;
  border: 2px solid #3ca4ed;
  overflow: hidden;
  position: relative;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.demo .btn:hover {
  color: #fff !important;
  background-color: #3ca4ed;
  border-color: #3ca4ed;
}
.demo #select .btn:hover,
.demo #i-pages .btn:hover {
  color: #fff;
  background-color: transparent;
  border-color: #fff;
}
.demo .btn:focus {
  color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
  /*------------------------------------------*/
  /*   SECTION TITLE    
   /*------------------------------------------*/
}
.demo span.section-id {
  color: #0abbb5;
  font-size: 0.825rem;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  display: block;
  letter-spacing: 0.5px;
  margin-bottom: 30px;
}
.demo .section-title {
  margin-bottom: 60px;
}
.demo .section-title h2 {
  color: #004861;
  font-size: 3rem;
  line-height: 1;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin-bottom: 20px;
}
.demo .section-title p {
  color: #858585;
  font-size: 1.25rem;
  padding: 0 18%;
  margin-bottom: 0;
}
.demo .section-title img {
  opacity: 0.7;
  margin-bottom: -30px;
  position: relative;
}
.demo .white-color,
.demo .white-color h2,
.demo .white-color h3,
.demo .white-color h4,
.demo .white-color h5,
.demo .white-color h6,
.demo .white-color p,
.demo .white-color a,
.demo .white-color li,
.demo .white-color i,
.demo .white-color span {
  color: #fff;
  /*------------------------------------------*/
  /*   PRELOAD SPINNER   
   /*------------------------------------------*/
}
.demo #loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: #fff;
}
.demo #loader {
  width: 256px;
  height: 38px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -128px;
  margin-top: -19px;
}
.demo .loader-inner {
  width: 256px;
  height: 38px;
  background: url(../images/loader.gif) center center no-repeat;
  /*------------------------------------------*/
  /*   HERO    
   /*------------------------------------------*/
}
.demo #hero {
  background-image: url(../images/hero.jpg);
  padding-top: 60px;
  padding-bottom: 330px;
}
.demo .hero-txt {
  position: relative;
}
.demo #hero h3 {
  color: #fff;
  font-size: 4rem;
  font-weight: 900;
  line-height: 1.25;
  padding: 0 8%;
  position: relative;
  z-index: 999;
}
.demo #hero p {
  color: #fff;
  font-size: 1.3rem;
  padding: 0 15%;
  margin-top: 25px;
}
.demo .hero-txt img {
  position: relative;
  margin-bottom: -125px;
  /*------------------------------------------*/
  /*	 SELECT    
   /*------------------------------------------*/
}
.demo #select {
  padding-bottom: 50px;
  margin-top: -285px;
  position: relative;
}
.demo #select .col-lg-4 {
  position: relative;
  z-index: 3;
  margin-bottom: 50px;
  text-align: center;
}
.demo .select_link h4 {
  color: #004861;
  font-size: 1.05rem;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-top: 40px;
}
.demo .select_link h4 span {
  color: #0abbb5;
  font-weight: 900;
  margin-left: 5px;
}
.demo .hover-overlay {
  width: 100%;
  height: auto;
  overflow: hidden;
  position: relative;
  /* -webkit-border-radius: 8px;
  -moz-border-radius: 8px; */
  /* border-radius: 8px; */
  -webkit-box-shadow: 0 0 10px rgba(10, 10, 10, 0.2);
  -moz-box-shadow: 0 0 10px rgba(10, 10, 10, 0.2);
  box-shadow: 0 0 10px rgba(10, 10, 10, 0.2);
}
.demo .hover-overlay img {
  -webkit-transition: all 600ms;
  -moz-transition: all 600ms;
  -o-transition: all 600ms;
  transition: all 600ms;
}
.demo .overlay-content {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  text-align: center;
  zoom: 1;
  opacity: 0;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
}
.demo .item-overlay {
  opacity: 0;
  -moz-opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(10, 10, 10, 0.65);
  -moz-transition: opacity 400ms ease-out;
  -webkit-transition: opacity 400ms ease-out;
  -o-transition: opacity 400ms ease-out;
  transition: opacity 400ms ease-out;
}
.demo .hover-overlay:hover > img {
  transform: scale(1.1);
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -o-transform: scale(1.1);
  -moz-transform: scale(1.1);
}
.demo .hover-overlay:hover .item-overlay {
  opacity: 1;
  -moz-opacity: 1;
}
.demo .select_link:hover .overlay-content {
  bottom: 43%;
  opacity: 1;
  -moz-opacity: 1;
  /*------------------------------------------*/
  /*   INNER PAGES
   /*------------------------------------------*/
}
.demo #i-pages {
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;
  z-index: 3;
}
.demo .ip-box {
  text-align: center;
  margin: 0 12px 60px;
  /*------------------------------------------*/
  /*   FEATURES
   /*------------------------------------------*/
}
.demo #features {
  padding-top: 20px;
  padding-bottom: 40px;
}
.demo .fbox {
  text-align: center;
  margin-bottom: 60px;
}
.demo .fbox img {
  width: 80px;
  height: 80px;
}
.demo .fbox h5 {
  line-height: 1;
  margin-top: 25px;
  margin-bottom: 8px;
}
.demo .fbox p {
  margin-bottom: 0;
}
.demo .features-btn {
  margin-top: 10px;
  /*------------------------------------------*/
  /*   Owl Carousel Dots
   /*------------------------------------------*/
}
.demo
  .inner-pages
  .owl-theme
  .swiper-pagination-bullets
  .swiper-pagination-bullet {
  background: transparent;
  border: 2px solid #004861;
  opacity: 0.7;
}
.demo
  .inner-pages
  .owl-theme
  .swiper-pagination-bullets
  .swiper-pagination-bullet.swiper-pagination-bullet-active,
.demo
  .inner-pages
  .owl-theme
  .swiper-pagination-bullets
  .swiper-pagination-bullet:hover {
  background: #004861;
  opacity: 1;
  /*------------------------------------------*/
  /*   BANNER
   /*------------------------------------------*/
}
.demo #banner-1 {
  margin-top: 100px;
  background-image: url(../images/banner-1.jpg);
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed !important;
}
.demo #banner-2 {
  padding-top: 100px;
  padding-bottom: 50px;
  background-color: #f0f0f0;
}
.demo #banner-1 .banner-txt {
  padding: 140px 60px 50px;
}
.demo #banner-2 .banner-txt {
  padding: 0 60px 50px;
}
.demo #banner-1 h2,
.demo #banner-2 h2 {
  font-size: 3rem;
  line-height: 1.2;
  font-weight: 700;
  letter-spacing: -1.5px;
  padding-right: 20px;
  margin-bottom: 35px;
}
.demo #banner-2 h2 {
  color: #004861;
}
.demo #banner-1 p {
  font-size: 1.15rem;
  margin-bottom: 0;
}
.demo #banner-2 p {
  color: #666;
  font-size: 1.15rem;
  margin-bottom: 40px;
}
.demo .banner-1-img {
  margin-top: -140px;
  position: relative;
  z-index: 999;
}
.demo #banner-2 .banner-img {
  padding-bottom: 50px;
  /*------------------------------------------*/
  /*	 FOOTER  
   /*------------------------------------------*/
}
.demo .footer {
  padding-top: 80px;
  padding-bottom: 100px;
  position: relative;
  z-index: 1;
}
.demo .footer p {
  color: #757575;
  font-weight: 400;
  line-height: 1.2rem;
  letter-spacing: 0;
  margin-bottom: 8px;
}
.demo .footer .svg-inline--fa {
  font-size: 1.15rem;
  /*------------------------------------------*/
  /*	  SCROLL TO TOP 
   /*------------------------------------------*/
}
.demo #scrollUp {
  display: none;
  width: 45px;
  height: 45px;
  position: fixed;
  bottom: 20px;
  right: 30px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background-image: url(../images/icons/back-to-top.png);
  background-repeat: no-repeat;
  background-position: 50% 48%;
  background-color: rgba(10, 10, 10, 0.65);
  -webkit-transition: all 250ms linear;
  -moz-transition: all 250ms linear;
  transition: all 250ms linear;
}
.demo #scrollUp:hover {
  background-color: rgba(0, 165, 200, 0.95);
}
.demo nav a#pull {
  display: none;
}
@media (min-width: 1441px) and (max-width: 1920.99px) {
  .demo .st-content {
    margin: 0 12%;
  }
  .demo .section-title p {
    padding: 0 22%;
  }
  .demo #select .col-lg-4 {
    max-width: 25%;
    flex: 0 0 25%;
  }
}
@media (min-width: 1281px) and (max-width: 1440px) {
  .demo .st-content {
    margin: 0 2%;
  }
  .demo .section-title h2 {
    font-size: 2.75rem;
  }
  .demo .section-title p {
    font-size: 1.1rem;
    padding: 0 20%;
  }
  .demo #hero {
    padding-top: 10px;
  }
  .demo .hero-txt img {
    width: 280px;
    height: 280px;
    margin-bottom: -95px;
  }
  .demo #hero h3 {
    font-size: 3.25rem;
    padding: 0 15%;
  }
  .demo #hero p {
    font-size: 1.2rem;
    padding: 0 15%;
    margin-top: 25px;
  }
  .demo .select_link h4 {
    font-size: 1rem;
    margin-top: 40px;
  }
  .demo .fbox h5 {
    font-size: 1.15rem;
    margin-bottom: 8px;
  }
  .demo .fbox p {
    font-size: 0.95rem;
  }
  .demo .fbox img {
    width: 80px;
    height: 80px;
  }
  .demo #banner-1 {
    margin-top: 80px;
  }
  .demo #banner-1 .banner-txt {
    padding: 120px 120px 50px 60px;
  }
  .demo #banner-2 .banner-txt {
    padding: 0 30px 50px;
  }
  .demo #banner-1 h2,
  .demo #banner-2 h2 {
    font-size: 2.5rem;
    letter-spacing: -0.5px;
    padding-right: 20px;
    margin-bottom: 25px;
  }
  .demo #banner-1 p {
    font-size: 1.05rem;
  }
  .demo #banner-2 p {
    font-size: 1.05rem;
    margin-bottom: 35px;
  }
  .demo .banner-1-img {
    margin-top: -100px;
  }
  .demo #banner-2 .banner-img {
    padding-bottom: 50px;
  }
  .demo .footer {
    padding-top: 80px;
    padding-bottom: 60px;
  }
}
@media (min-width: 1200px) and (max-width: 1280.99px) {
  .demo .st-content {
    margin: 0 2%;
  }
  .demo .section-title h2 {
    font-size: 2.75rem;
  }
  .demo .section-title p {
    font-size: 1.1rem;
    padding: 0 20%;
  }
  .demo #hero {
    padding-top: 10px;
  }
  .demo .hero-txt img {
    width: 280px;
    height: 280px;
    margin-bottom: -95px;
  }
  .demo #hero h3 {
    font-size: 3.25rem;
    padding: 0 15%;
  }
  .demo #hero p {
    font-size: 1.2rem;
    padding: 0 15%;
    margin-top: 25px;
  }
  .demo .select_link h4 {
    font-size: 1rem;
    margin-top: 40px;
  }
  .demo .fbox h5 {
    font-size: 1.15rem;
    margin-bottom: 8px;
  }
  .demo .fbox p {
    font-size: 0.95rem;
  }
  .demo .fbox img {
    width: 80px;
    height: 80px;
  }
  .demo #banner-1 {
    margin-top: 80px;
  }
  .demo #banner-1 .banner-txt {
    padding: 125px 50px 50px 60px;
  }
  .demo #banner-2 .banner-txt {
    padding: 0 50px 50px;
  }
  .demo #banner-1 h2,
  .demo #banner-2 h2 {
    font-size: 2.5rem;
    letter-spacing: -0.5px;
    padding-right: 20px;
    margin-bottom: 25px;
  }
  .demo #banner-1 p {
    font-size: 1rem;
  }
  .demo #banner-2 p {
    font-size: 1rem;
    margin-bottom: 35px;
  }
  .demo .banner-1-img {
    margin-top: -100px;
  }
  .demo #banner-2 .banner-img {
    padding-bottom: 50px;
  }
  .demo .footer {
    padding-top: 80px;
    padding-bottom: 60px;
  }
}
@media (min-width: 992px) and (max-width: 1199.99px) {
  .demo .st-content {
    margin: 0 2%;
  }
  .demo .btn {
    font-size: 0.815rem;
    padding: 14px 28px;
  }
  .demo .section-title h2 {
    font-size: 2.5rem;
  }
  .demo .section-title p {
    font-size: 1.1rem;
    padding: 0 12%;
  }
  .demo span.section-id {
    font-size: 0.8rem;
    margin-bottom: 20px;
  }
  .demo #hero {
    padding-top: 0;
    padding-bottom: 200px;
  }
  .demo .hero-txt img {
    width: 240px;
    height: 240px;
    margin-bottom: -75px;
  }
  .demo #hero h3 {
    font-size: 2.85rem;
    padding: 0 15%;
  }
  .demo #hero p {
    font-size: 1.1rem;
    padding: 0 12%;
    margin-top: 25px;
  }
  .demo #select {
    padding-bottom: 60px;
    margin-top: -165px;
  }
  .demo #select .col-lg-4 {
    margin-bottom: 40px;
  }
  .demo .select_link h4 {
    font-size: 1rem;
    margin-top: 40px;
  }
  .demo .fbox {
    width: 33.333%;
    padding-top: 20px;
    margin-bottom: 60px;
  }
  .demo .fbox h5 {
    font-size: 1.15rem;
  }
  .demo #banner-1 {
    margin-top: 70px;
  }
  .demo #banner-1 .banner-txt {
    padding: 60px 0 0 20px;
  }
  .demo #banner-2 .banner-txt {
    padding: 0 30px 50px;
  }
  .demo #banner-1 h2,
  .demo #banner-2 h2 {
    font-size: 2.25rem;
    letter-spacing: -0.5px;
    padding-right: 0;
    margin-bottom: 20px;
  }
  .demo #banner-1 p {
    font-size: 1rem;
  }
  .demo #banner-2 p {
    font-size: 1rem;
    margin-bottom: 30px;
  }
  .demo .banner-1-img {
    margin-top: -100px;
  }
  .demo #banner-2 .banner-img {
    padding-bottom: 50px;
  }
  .demo .footer {
    padding-top: 80px;
    padding-bottom: 60px;
  }
}
@media (min-width: 768px) and (max-width: 991.99px) {
  .demo .st-content {
    margin: 0 2%;
  }
  .demo .btn {
    font-size: 0.815rem;
    padding: 14px 28px;
  }
  .demo .section-title {
    margin-bottom: 60px;
  }
  .demo .section-title h2 {
    font-size: 2.25rem;
  }
  .demo .section-title p {
    font-size: 1rem;
    padding: 0 12%;
  }
  .demo span.section-id {
    font-size: 0.8rem;
    margin-bottom: 20px;
  }
  .demo #hero {
    padding-top: 0;
    padding-bottom: 200px;
  }
  .demo .hero-txt img {
    width: 240px;
    height: 240px;
    margin-bottom: -75px;
  }
  .demo #hero h3 {
    font-size: 2.75rem;
    padding: 0 8%;
  }
  .demo #hero p {
    font-size: 1.05rem;
    padding: 0 4%;
    margin-top: 25px;
  }
  .demo #select {
    padding-bottom: 60px;
    margin-top: -165px;
  }
  .demo #select .col-lg-4 {
    margin-bottom: 40px;
  }
  .demo .select_link h4 {
    font-size: 1rem;
    margin-top: 40px;
  }
  .demo #i-pages {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .demo .fbox {
    width: 50%;
    padding-top: 10px;
    margin-bottom: 60px;
  }
  .demo .fbox h5 {
    font-size: 1.3rem;
  }
  .demo #banner-1 {
    margin-top: 0;
    padding-top: 70px;
    padding-bottom: 70px;
    text-align: center;
  }
  .demo #banner-2 {
    text-align: center;
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .demo #banner-1 .banner-txt,
  .demo #banner-2 .banner-txt {
    padding: 0;
  }
  .demo #banner-1 h2,
  .demo #banner-2 h2 {
    font-size: 2.25rem;
    letter-spacing: -0.5px;
    padding-right: 0;
    margin-bottom: 20px;
  }
  .demo #banner-1 p {
    font-size: 1rem;
    padding: 0 12%;
  }
  .demo #banner-2 p {
    font-size: 1rem;
    padding: 0 8%;
    margin-bottom: 30px;
  }
  .demo .banner-1-img {
    display: none;
  }
  .demo #banner-2 .banner-img {
    padding-bottom: 50px;
  }
  .demo .footer {
    padding-top: 80px;
    padding-bottom: 60px;
  }
}
@media (min-width: 576px) and (max-width: 767.99px) {
  .demo .st-content {
    margin: 0 4%;
  }
  .demo .btn {
    font-size: 0.815rem;
    padding: 14px 28px;
  }
  .demo .section-title {
    margin-bottom: 45px;
  }
  .demo .section-title h2 {
    font-size: 2rem;
  }
  .demo .section-title p {
    font-size: 1rem;
    padding: 0 8%;
  }
  .demo span.section-id {
    font-size: 0.8rem;
    margin-bottom: 20px;
  }
  .demo #hero {
    padding-top: 0;
    padding-bottom: 200px;
  }
  .demo .hero-txt img {
    width: 240px;
    height: 240px;
    margin-bottom: -75px;
  }
  .demo #hero h3 {
    font-size: 2.25rem;
    padding: 0;
  }
  .demo #hero p {
    font-size: 1.05rem;
    padding: 0 4%;
    margin-top: 20px;
  }
  .demo #select {
    padding-bottom: 20px;
    margin-top: -180px;
  }
  .demo #select .col-lg-4 {
    margin-bottom: 40px;
  }
  .demo .select_link h4 {
    font-size: 1.15rem;
    margin-top: 40px;
  }
  .demo #i-pages {
    padding-top: 80px;
    padding-bottom: 0;
  }
  .demo
    .inner-pages
    .owl-theme
    .swiper-pagination-bullets
    .swiper-pagination-bullet {
    display: none;
  }
  .demo #features {
    padding-bottom: 30px;
  }
  .demo .fbox {
    width: 100%;
    padding-top: 0;
    margin-bottom: 50px;
  }
  .demo .fbox h5 {
    font-size: 1.3rem;
  }
  .demo #banner-1 {
    margin-top: 0;
    padding-top: 70px;
    padding-bottom: 70px;
    text-align: center;
  }
  .demo #banner-2 {
    text-align: center;
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .demo #banner-1 .banner-txt,
  .demo #banner-2 .banner-txt {
    padding: 0;
  }
  .demo #banner-1 h2,
  .demo #banner-2 h2 {
    font-size: 2rem;
    letter-spacing: -0.5px;
    padding-right: 0;
    margin-bottom: 15px;
  }
  .demo #banner-1 p {
    font-size: 1rem;
    padding: 0 3%;
  }
  .demo #banner-2 p {
    font-size: 1rem;
    padding: 0 6%;
    margin-bottom: 30px;
  }
  .demo .banner-1-img {
    display: none;
  }
  .demo #banner-2 .banner-img {
    padding-bottom: 50px;
  }
  .demo .footer {
    padding-top: 60px;
    padding-bottom: 70px;
  }
}
@media (max-width: 575px) {
  .demo .st-content {
    margin: 0 2%;
  }
  .demo .btn {
    font-size: 0.815rem;
    padding: 14px 10px;
  }
  .demo .section-title {
    margin-bottom: 40px;
  }
  .demo .section-title h2 {
    font-size: 1.85rem;
    line-height: 1.2;
    padding: 0 5%;
  }
  .demo .section-title p {
    font-size: 1rem;
    padding: 0%;
  }
  .demo span.section-id {
    font-size: 0.8rem;
    margin-bottom: 20px;
  }
  .demo #hero {
    padding-top: 0;
    padding-bottom: 200px;
  }
  .demo .hero-txt img {
    width: 200px;
    height: 200px;
    margin-bottom: -55px;
  }
  .demo #hero h3 {
    font-size: 1.5rem;
    padding: 0;
  }
  .demo #hero p {
    font-size: 1rem;
    padding: 0 1%;
    margin-top: 20px;
  }
  .demo #select {
    padding-bottom: 20px;
    margin-top: -180px;
  }
  .demo #select .col-lg-4 {
    margin-bottom: 30px;
  }
  .demo .select_link h4 {
    font-size: 1.1rem;
    margin-top: 30px;
  }
  .demo #i-pages {
    padding-top: 80px;
    padding-bottom: 0;
  }
  .demo
    .inner-pages
    .owl-theme
    .swiper-pagination-bullets
    .swiper-pagination-bullet {
    display: none;
  }
  .demo #features {
    padding-bottom: 30px;
  }
  .demo .fbox {
    width: 100%;
    padding-top: 0;
    margin-bottom: 50px;
  }
  .demo .fbox h5 {
    font-size: 1.3rem;
  }
  .demo .fbox img {
    width: 70px;
    height: 70px;
  }
  .demo #banner-1 {
    margin-top: 0;
    padding-top: 70px;
    padding-bottom: 70px;
    text-align: center;
  }
  .demo #banner-2 {
    text-align: center;
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .demo #banner-1 .banner-txt,
  .demo #banner-2 .banner-txt {
    padding: 0;
  }
  .demo #banner-1 h2,
  .demo #banner-2 h2 {
    font-size: 1.85rem;
    letter-spacing: -0.5px;
    padding-right: 0;
    margin-bottom: 15px;
  }
  .demo #banner-1 p {
    font-size: 1rem;
    padding: 0 2%;
  }
  .demo #banner-2 p {
    font-size: 1rem;
    padding: 0;
    margin-bottom: 25px;
  }
  .demo .banner-1-img {
    display: none;
  }
  .demo #banner-2 .banner-img {
    padding-bottom: 50px;
  }
  .demo .footer {
    padding-top: 60px;
    padding-bottom: 70px;
  }
}
