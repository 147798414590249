
.cd-schedule {
  position: relative;
  margin: 0;
}

.cd-schedule::before {
  content: 'mobile';
  display: none;
}

@media only screen and (min-width: 800px) {

  .cd-schedule { width: 95%; max-width: 1400px; margin: 0 auto; }
  .cd-schedule::after { clear: both; content: ""; display: block; }
  .cd-schedule::before { content: 'desktop'; }

}

.cd-schedule .timeline {
  display: none;
}

@media only screen and (min-width: 800px) {

  .cd-schedule .timeline { display: block; position: absolute; top: 0; left: 0; height: 100%; width: 100%; padding-top: 50px; }
  .cd-schedule .timeline li { position: relative; height: 50px; }

  /* this is used to create the table horizontal lines */
  .cd-schedule .timeline li::after { content: ''; position: absolute; bottom: 0; left: 0; width: 100%; height: 1px; background: #eaeaea; }
  .cd-schedule .timeline li:last-of-type::after { display: none; }
  .cd-schedule .timeline li span { display: none; }

}

@media only screen and (min-width: 1000px) {

  .cd-schedule .timeline li::after { width: calc(100% - 60px); left: 60px; }
  .cd-schedule .timeline li span { display: inline-block; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%); transform: translateY(-50%); }
  .cd-schedule .timeline li:nth-of-type(2n) span {display: none;}
}

.cd-schedule .events {
  position: relative;
  z-index: 1;
}

.cd-schedule .events .events-group {
  margin-bottom: 30px;
}

.cd-schedule .events .top-info {
  width: 100%;
  padding: 0 5%;
}

.cd-schedule .events .top-info > span {
  display: inline-block;
  line-height: 1.2;
  margin-bottom: 10px;
  font-weight: bold;
}

.cd-schedule .events .events-group > ul {
  position: relative;
  padding: 0 5%;
  /* force its children to stay on one line */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
}

.cd-schedule .events .events-group > ul::after {
  /* never visible - used to add a right padding to .events-group > ul */
  display: inline-block;
  content: '-';
  width: 1px;
  height: 100%;
  opacity: 0;
  color: transparent;
}

.cd-schedule .events .single-event {
  /* force them to stay on one line */
  -ms-flex-negative: 0;
      flex-shrink: 0;
  float: left;
  height: 150px;
  width: 70%;
  max-width: 300px;
  box-shadow: inset 0 -3px 0 rgba(0, 0, 0, 0.2);
  margin-right: 20px;
  -webkit-transition: opacity .2s, background .2s;
  transition: opacity .2s, background .2s;
}

.cd-schedule .events .single-event:last-of-type {
  margin-right: 5%;
}

.cd-schedule .events .event-info
/*.cd-schedule .events .single-event a,
.cd-schedule .events .single-event span */ {
  display: block;
  height: 100%;
  padding: 10px;
}

@media only screen and (min-width: 550px) {
  .cd-schedule .events .single-event {
    width: 40%;
  }
}

@media only screen and (min-width: 800px) {
  .cd-schedule .events {
    float: left;
    width: 100%;
  }
  .cd-schedule .events .events-group {
    width: 20%;
    float: left;
    border: 1px solid #EAEAEA;
    /* reset style */
    margin-bottom: 0;
  }
  .cd-schedule .events .events-group:not(:first-of-type) {
    border-left-width: 0;
  }
  .cd-schedule .events .top-info {
    /* vertically center its content */
    display: table;
    height: 50px;
    border-bottom: 1px solid #EAEAEA;
    /* reset style */
    padding: 0;
  }
  .cd-schedule .events .top-info > span {
    /* vertically center inside its parent */
    display: table-cell;
    vertical-align: middle;
    padding: 0 .5em;
    text-align: center;
    /* reset style */
    font-weight: normal;
    margin-bottom: 0;
  }
  .cd-schedule .events .events-group > ul {
    height: 950px;
    /* reset style */
    display: block;
    overflow: visible;
    padding: 0;
  }
  .cd-schedule .events .events-group > ul::after {
    clear: both;
    content: "";
    display: block;
  }
  .cd-schedule .events .events-group > ul::after {
    /* reset style */
    display: none;
  }
  .cd-schedule .events .single-event {
    position: absolute;
    z-index: 3;
    /* top position and height will be set using js */
    width: calc(100% + 2px);
    left: -1px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1), inset 0 -3px 0 rgba(0, 0, 0, 0.2);
    /* reset style */
    -ms-flex-negative: 1;
        flex-shrink: 1;
    height: auto;
    max-width: none;
    margin-right: 0;
  }/*
  .cd-schedule .events .single-event a {
    padding: 1.2em;
  }*/
  .cd-schedule .events .single-event:last-of-type {
    /* reset style */
    margin-right: 0;
  }
  .cd-schedule .events .single-event.selected-event {
    /* the .selected-event class is added when an user select the event */
    visibility: hidden;
  }
}

@media only screen and (min-width: 1000px) {
  .cd-schedule .events {
    /* 60px is the .timeline element width */
    width: calc(100% - 60px);
    margin-left: 60px;
  }
}

.cd-schedule.loading .events .single-event {
  opacity: 0;
}

.cd-schedule .event-name,
.cd-schedule .event-date {
  text-align: center;
  display: block;
  color: white;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cd-schedule .event-name {
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.15;
  font-style: normal;
   margin-bottom: 4px;
}

.cd-schedule .event-name.event-doctor {
  font-size: 1rem;
  font-family: 'Lato', sans-serif;
  font-weight: 900;
  font-style: normal;
  margin-bottom: 3px;
}

.cd-schedule .event-name.event-room {
  font-size: 0.875rem;
  font-weight: 400;
  font-style: normal;
  margin-top: 8px;
}
/*
@media only screen and (min-width: 800px) {
  .cd-schedule .event-name {
    font-size: 2rem;
  }
}*/

.cd-schedule .event-date {
  /* they are not included in the the HTML but added using JavScript */
  font-size: 0.975rem;
  font-weight: 900;
  opacity: 1;
  line-height: 1.2;
  margin-bottom: 10px;
}

.cd-schedule .single-event[data-event="event-1"],
.cd-schedule [data-event="event-1"] .header-bg {
  /* this is used to set a background color for the event and the modal window */
  background: #00a3c8;
}

.cd-schedule .single-event[data-event="event-1"]:hover {
  background: #0092b3;
}

.cd-schedule .single-event[data-event="event-2"],
.cd-schedule [data-event="event-2"] .header-bg {
  background: #6cc8df;
}

.cd-schedule .single-event[data-event="event-2"]:hover {
  background: #61b3c8;
}

.cd-schedule .single-event[data-event="event-3"],
.cd-schedule [data-event="event-3"] .header-bg {
  background: #b1d259;
}

.cd-schedule .single-event[data-event="event-3"]:hover {
  background: #a2c051;
}

.cd-schedule .single-event[data-event="event-4"],
.cd-schedule [data-event="event-4"] .header-bg {
  background: #929da7;
}

.cd-schedule .single-event[data-event="event-4"]:hover {
  background: #86919a;
}

.cd-schedule .single-event[data-event="event-5"],
.cd-schedule [data-event="event-5"] .header-bg {
  background: #56b0d2;
}

.cd-schedule .single-event[data-event="event-5"]:hover {
  background: #4d9ebd;
}

.cd-schedule .single-event[data-event="event-6"],
.cd-schedule [data-event="event-5"] .header-bg {
  background: #62d5b8;
}

.cd-schedule .single-event[data-event="event-6"]:hover {
  background: #58c0a6;
}

.cd-schedule .single-event[data-event="event-7"],
.cd-schedule [data-event="event-5"] .header-bg {
  background: #1977cc;
}

.cd-schedule .single-event[data-event="event-7"]:hover {
  background: #176dba;
}

.cd-schedule .single-event[data-event="event-8"],
.cd-schedule [data-event="event-5"] .header-bg {
  background: #e88d65;
}

.cd-schedule .single-event[data-event="event-8"]:hover {
  background: #d2805c;
}

.cd-schedule .event-modal {
  position: fixed;
  z-index: 3;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  visibility: hidden;
  /* Force Hardware acceleration */
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  -webkit-transform: translateX(100%);
      -ms-transform: translateX(100%);
          transform: translateX(100%);
  -webkit-transition: visibility .4s, -webkit-transform .4s;
  transition: visibility .4s, -webkit-transform .4s;
  transition: transform .4s, visibility .4s;
  transition: transform .4s, visibility .4s, -webkit-transform .4s;
  -webkit-transition-timing-function: cubic-bezier(0.5, 0, 0.1, 1);
          transition-timing-function: cubic-bezier(0.5, 0, 0.1, 1);
}

.cd-schedule .event-modal .header {
  position: relative;
  height: 70px;
  /* vertically center its content */
  display: table;
  width: 100%;
}

.cd-schedule .event-modal .header .content {
  position: relative;
  z-index: 3;
  /* vertically center inside its parent */
  display: table-cell;
  vertical-align: middle;
  padding: .6em 5%;
}

.cd-schedule .event-modal .body {
  position: relative;
  width: 100%;
  /* 70px is the .header height */
  height: calc(100% - 70px);
}

.cd-schedule .event-modal .event-info {
  position: relative;
  z-index: 2;
  line-height: 1.4;
  height: 100%;
  overflow: hidden;
}

.cd-schedule .event-modal .event-info > div {
  overflow: auto;
  height: 100%;
  padding: 1.4em 5%;
}

.cd-schedule .event-modal .header-bg, .cd-schedule .event-modal .body-bg {
  /* these are the morphing backgrounds - visible on desktop only */
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cd-schedule .event-modal .body-bg {
  z-index: 1;
  background: white;
  -webkit-transform-origin: top left;
      -ms-transform-origin: top left;
          transform-origin: top left;
}

.cd-schedule .event-modal .header-bg {
  z-index: 2;
  -webkit-transform-origin: top center;
      -ms-transform-origin: top center;
          transform-origin: top center;
}

.cd-schedule .event-modal .close {
  /* this is the 'X' icon */
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
  background: rgba(0, 0, 0, 0.1);
  /* replace text with icon */
  color: transparent;
  white-space: nowrap;
  text-indent: 100%;
  height: 70px;
  width: 70px;
}

.cd-schedule .event-modal .close::before, .cd-schedule .event-modal .close::after {
  /* these are the two lines of the 'X' icon */
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2px;
  height: 22px;
  background: white;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.cd-schedule .event-modal .close::before {
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
      -ms-transform: translateX(-50%) translateY(-50%) rotate(45deg);
          transform: translateX(-50%) translateY(-50%) rotate(45deg);
}

.cd-schedule .event-modal .close::after {
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
      -ms-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
          transform: translateX(-50%) translateY(-50%) rotate(-45deg);
}

.cd-schedule .event-modal .event-date {
  display: none;
}

.cd-schedule .event-modal.no-transition {
  -webkit-transition: none;
  transition: none;
}

.cd-schedule .event-modal.no-transition .header-bg, .cd-schedule .event-modal.no-transition .body-bg {
  -webkit-transition: none;
  transition: none;
}

@media only screen and (min-width: 800px) {
  .cd-schedule .event-modal {
    /* reset style */
    right: auto;
    width: auto;
    height: auto;
    -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
            transform: translateX(0);
    will-change: transform, width, height;
    -webkit-transition: height .4s, width .4s, visibility .4s, -webkit-transform .4s;
    transition: height .4s, width .4s, visibility .4s, -webkit-transform .4s;
    transition: height .4s, width .4s, transform .4s, visibility .4s;
    transition: height .4s, width .4s, transform .4s, visibility .4s, -webkit-transform .4s;
    -webkit-transition-timing-function: cubic-bezier(0.5, 0, 0.1, 1);
            transition-timing-function: cubic-bezier(0.5, 0, 0.1, 1);
  }
  .cd-schedule .event-modal .header {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    height: 100%;
  }
  .cd-schedule .event-modal .header .content {
    /* reset style */
    display: block;
    padding: .8em;
  }
  .cd-schedule .event-modal .event-info > div {
    padding: 2em 3em 2em 2em;
  }
  .cd-schedule .event-modal .body {
    height: 100%;
    width: auto;
  }
  .cd-schedule .event-modal .header-bg, .cd-schedule .event-modal .body-bg {
    /* Force Hardware acceleration */
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    will-change: transform;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
  }
  .cd-schedule .event-modal .header-bg {
    -webkit-transition: -webkit-transform .4s;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
    -webkit-transition-timing-function: cubic-bezier(0.5, 0, 0.1, 1);
            transition-timing-function: cubic-bezier(0.5, 0, 0.1, 1);
  }
  .cd-schedule .event-modal .body-bg {
    opacity: 0;
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
  }
  .cd-schedule .event-modal .event-date {
    display: block;
  }
  .cd-schedule .event-modal .close, .cd-schedule .event-modal .event-info {
    opacity: 0;
  }
  .cd-schedule .event-modal .close {
    width: 40px;
    height: 40px;
    background: transparent;
  }
  .cd-schedule .event-modal .close::after, .cd-schedule .event-modal .close::before {
    background: #222222;
    height: 16px;
  }
}

@media only screen and (min-width: 1000px) {
  .cd-schedule .event-modal .header .content {
    padding: 1.2em;
  }
}

.cd-schedule.modal-is-open .event-modal {
  /* .modal-is-open class is added as soon as an event is selected */
  -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
          transform: translateX(0);
  visibility: visible;
}

.cd-schedule.modal-is-open .event-modal .event-info > div {
  /* smooth scroll on iOS touch devices */
  -webkit-overflow-scrolling: touch;
}

@media only screen and (min-width: 800px) {
  .cd-schedule.animation-completed .event-modal .close,
  .cd-schedule.content-loaded.animation-completed .event-modal .event-info {
    /* 	the .animation-completed class is added when the modal animation is completed
			the .content-loaded class is added when the modal content has been loaded (using ajax) */
    opacity: 1;
    -webkit-transition: opacity .2s;
    transition: opacity .2s;
  }
  .cd-schedule.modal-is-open .body-bg {
    opacity: 1;
    -webkit-transition: -webkit-transform .4s;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
    -webkit-transition-timing-function: cubic-bezier(0.5, 0, 0.1, 1);
            transition-timing-function: cubic-bezier(0.5, 0, 0.1, 1);
  }
}

.cd-schedule .cover-layer {
  /* layer between the content and the modal window */
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity .4s, visibility .4s;
  transition: opacity .4s, visibility .4s;
}

.cd-schedule.modal-is-open .cover-layer {
  opacity: 1;
  visibility: visible;
}
