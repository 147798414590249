/* @import "https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap";
@import "https://fonts.googleapis.com/css?family=Lato:400,700,900,900&display=swap"; */
@import "https://use.fontawesome.com/releases/v5.7.2/css/all.css";
@import "./bootstrap.min";
@import "./demo-style";

@import "swiper/css";
@import "./animate.min.css";
@import "./owl.carousel.min.css";
@import "./owl.theme.default.min.css";
@import "./flaticon.css";
@import "./dropdown-effects/fade-down.css";
@import "./magnific-popup.css";
@import "./timetable.css";
@import "./menu.css";
@import "./style.css";
@import "./responsive.css";

.owl-carousel {
  display: block !important;
}

.swiper .ip-box {
  text-align: center;
  padding: 0 12px 60px;
  margin: 0 !important;
}

.swiper .sbox-3,
.swiper .review-2,
.swiper .sbox-5 {
  margin: 0 0px 40px;
}

img.mfp-img {
  box-shadow: 0 0 8px rgb(0 0 0 / 60%);
  position: absolute;
  max-height: 392px;
  padding: 0 !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.mfp-img-container .mfp-content {
  max-width: 400px !important;
}
.mfp-img-container .mfp-close {
  top: -110px;
  right: -24px;
}

.stricky-fixed .headtoppart {
  display: none;
}
.stricky-fixed .menu {
  position: fixed;
  top: 0px;
  margin-top: 0px;
  z-index: 500;
  display: block;
  left: 0px;
}

.blue-color {
  color: #0abbb5 !important;
}
